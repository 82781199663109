import { NotFound } from "components/404/404"
import React from "react"

const NotFoundPage = () => (
  // <PageWrapper>
  //   {/* <SEO title="404: Not found" /> */}
  //   <h1>NOT FOUND</h1>
  //   <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  // </PageWrapper>
  <NotFound />
)

export default NotFoundPage
